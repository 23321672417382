import React, { useContext, useEffect } from "react";
import { SystemContext } from "../components/SystemConfig/SystemContext";
import { Spinner, Text } from "@backyard-ui/core";
import "./LeroyIntermediary.css";

const LeroyIntermediary = () => {
  const { company } = useContext(SystemContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      const urlOpen = `/environments`;
      window.open(urlOpen, "_self");
    }, 1200); // 6 segundos

    return () => clearTimeout(timer); // Limpa o timer quando o componente desmonta
  }, []);

  return (
    <div className="container_leroy">
      <main>
        {/* Logo Leroy Merlin */}
        <svg
          className="logo_leroy"
          viewBox="0 0 1893 1150"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M195.779 1149.39H1687.17L945.429 400.28L195.779 1149.39Z"
            fill="#79BE21"
          />
          <path
            d="M244.77 957.23L171.79 1029.13L43.22 896L0 938.62L167.28 1111.79L283.47 997.3L244.77 957.23Z"
            fill="black"
          />
          <path
            d="M428.03 853.87L395.34 820.06L319.75 894.53L283.44 856.94L344.02 797.27L309.8 761.85L249.22 821.52L218.12 789.31L291.24 717.27L258.56 683.44L139.88 800.4L308.97 971.21L428.03 853.87Z"
            fill="black"
          />
          <path
            d="M832.58 456.23L876.52 412.94L810.42 344.55L770.87 178.33L720.52 227.96L746.71 322.24L658.2 289.33L610.74 336.1L761.62 385.04L832.58 456.23Z"
            fill="black"
          />
          <path
            d="M910.66 122.03L853.45 246.22L878.9 272.13L1001.97 215.73L912 305.83L953.14 347.73L1123.56 177.01L1069.88 122.36L944.72 179.43L1006.11 57.4L950.33 0.609985L779.91 171.32L818.94 211.07L910.66 122.03Z"
            fill="black"
          />
          <path
            d="M1114.85 512.39L1149.99 477.18L1076.45 402.29L1113.71 365L1171.9 424.26L1207.74 388.36L1149.19 328.74L1176.95 300.92L1251.18 376.52L1285.63 342.04L1167.13 221.36L996.68 392.08L1114.85 512.39Z"
            fill="black"
          />
          <path
            d="M1450.13 853.8L1491.59 812.25L1419.45 738.81L1548.42 609.62L1504.5 564.91L1334.07 735.63L1450.13 853.8Z"
            fill="black"
          />
          <path
            d="M1523.67 928.7L1694.1 757.99L1651.23 714.32L1480.8 885.04L1523.67 928.7Z"
            fill="black"
          />
          <path
            d="M1853.02 919.8L1740.23 1032.8L1789.95 855.55L1731.73 796.31L1561.3 967.02L1601.39 1007.84L1714.56 892.36L1670.75 1078.46L1721.65 1130.25L1892.06 959.56L1853.02 919.8Z"
            fill="black"
          />
          <path
            d="M1334.15 458.86C1334.15 458.86 1362.04 487.26 1365.54 490.81C1369.01 494.34 1390.98 511.06 1364.27 536.4C1364.27 536.4 1342.54 551.15 1323.35 531.63L1293.39 499.71C1293.39 499.71 1329.6 463.45 1334.16 458.86M1197.1 596.16L1290.22 502.87L1256.7 656.84L1301.67 702.62C1301.67 702.62 1337.09 592.5 1338.17 587.89C1338.17 587.89 1376.59 609.26 1418.72 572.68C1418.72 572.68 1459.14 532.91 1427.48 486.48L1323.98 381.06L1153.19 551.43L1197.1 596.16Z"
            fill="black"
          />
          <path
            d="M364.39 648.36C363.01 646.91 392.19 621.66 396.8 617.11C398.75 615.21 416.91 603.23 431.96 618.79C431.96 618.79 457.62 639.26 432.8 663.7C408.02 688.12 404.67 690.02 404.67 690.02C404.67 690.02 365.78 649.76 364.4 648.36M497.81 786.1L406.52 691.59L554.1 730.89L598.38 687.26C598.38 687.26 491.47 650.43 486.39 651.31C484.75 651.59 509.35 585.46 454.06 556.13C454.06 556.13 413.03 533.71 371.98 571.39L284.46 658.48L453.17 830.07L497.81 786.09V786.1Z"
            fill="black"
          />
          <path
            d="M560.6 448.84C576.2 433.48 607.86 419.72 645.52 458.74C688.52 503.24 689.93 529.05 663.36 555.21C636.81 581.4 604.33 574.27 567.23 535.86C536.06 503.59 537.92 471.16 560.6 448.83M699.71 589.24C758.86 530.95 737.8 455.48 698.66 414.93C650.64 365.24 567.35 371.18 527.25 410.71C487.13 450.23 466.66 529.09 523.3 587.74C585.55 652.16 659.58 628.78 699.72 589.24"
            fill="black"
          />
        </svg>

        {/* Spinner */}
        <Spinner className="loader_leroy" color="primary" size="xl" />

        {/* Texto */}
        <Text color="tertiary-600" size="2xl" className="text_leroy">
          Estamos redirecionando você para a plataforma de decoração online
        </Text>
      </main>
      <footer>
        <Text size="sm" className="text_leroy">
          por prodeco
        </Text>
      </footer>
    </div>
  );
};

export default LeroyIntermediary;
