import React, { useContext, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";

import { SystemContext } from "./components/SystemConfig/SystemContext";

import PrivateRoute from "./components/common/Authorization/PrivateRoute";
import withTracker from "./withTracker";

// Layout Types
import { DefaultLayout } from "./layouts";
import WithoutSidebarLayout from "./layouts/WithoutSidebar";

// Route Views
import Login from "./views/Login";
import ValidatorAdmin from "./views/ValidatorAdmin";
import ForgotPassword from "./views/ForgotPassword";
import ChangePassword from "./views/ChangePassword";
import Register from "./views/Register";
import Environments from "./views/Environments";
import TransactionHistory from "./views/TransactionHistory";
import FaqPlataforma from "./views/FaqPlataforma";
import EnterprisesFaq from "./views/EnterprisesFaq";
import Exemple from "./views/Exemple";
import Checkout from "./views/Checkout";
import Summary from "./views/Summary";
import Enterprises from "./views/Enterprises";
import EnterpriseView from "./views/Enterprise";
import Support from "./views/Support";
import AuthThenRedirect from "./views/AuthThenRedirect";
import EnterpriseAnswers from "./views/EnterpriseAnswers";
import Profile from "./views/Profile";
import SharedProject from "./views/SharedProject";
import Start from "./components/environments/steps/Start";
import Validator from "./components/Login/Validator";
import Gift from "./views/Gift";
import Projects from "./views/Projects";
import Project from "./views/Project";
import LandingPage from "./views/LandingPage";
import ExternalLogin from "./views/ExternalLogin";
import LeroyIntermediary from "./views/LeroyIntermediary";

export const PUBLIC_ROUTES = [
  {
    path: "/login",
    layout: WithoutSidebarLayout,
    component: Login,
  },
  {
    path: "/auth/google",
    layout: WithoutSidebarLayout,
    component: ExternalLogin,
  },
  {
    path: "/auth",
    layout: WithoutSidebarLayout,
    component: AuthThenRedirect,
  },
  {
    path: "/login-admin",
    layout: WithoutSidebarLayout,
    component: ValidatorAdmin,
  },
  {
    path: "/register",
    layout: WithoutSidebarLayout,
    component: Register,
  },
  {
    path: "/forgot-password",
    layout: WithoutSidebarLayout,
    component: ForgotPassword,
  },
  {
    path: "/change-password",
    layout: WithoutSidebarLayout,
    component: ChangePassword,
  },
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/environments" />,
  },
  {
    path: "/environments",
    layout: DefaultLayout,
    component: Environments,
  },
  {
    path: "/exemple",
    layout: DefaultLayout,
    component: Exemple,
  },
  {
    path: "/exemple-enterprise",
    layout: DefaultLayout,
    component: Exemple,
  },
  {
    path: "/faq",
    layout: DefaultLayout,
    component: FaqPlataforma,
  },
  {
    path: "/entreprises-faq",
    layout: DefaultLayout,
    component: EnterprisesFaq,
  },

  {
    path: "/support",
    layout: DefaultLayout,
    component: Support,
  },
  {
    path: "/project",
    layout: DefaultLayout,
    component: SharedProject,
  },
  {
    path: "/validator-user",
    layout: WithoutSidebarLayout,
    component: Validator,
  },
  {
    path: "/validator",
    layout: WithoutSidebarLayout,
    component: Validator,
  },
  {
    path: "/gift/:label",
    layout: WithoutSidebarLayout,
    component: Gift,
  },
  {
    path: "/landing-page",
    layout: WithoutSidebarLayout,
    component: LandingPage,
  },
  {
    path: "/leroy-redirect",
    layout: WithoutSidebarLayout,
    component: LeroyIntermediary,
  },
];

const PRIVATE_ROUTES = [
  {
    path: "/transaction-history",
    layout: DefaultLayout,
    component: TransactionHistory,
  },
  {
    path: "/checkout",
    layout: DefaultLayout,
    component: Checkout,
  },
  { path: "/summary/:id", layout: DefaultLayout, component: Summary },
  {
    path: "/enterprises",
    layout: DefaultLayout,
    component: Enterprises,
  },
  {
    path: "/enterprises-answer",
    layout: DefaultLayout,
    component: EnterpriseAnswers,
  },
  {
    path: "/enterprise/:id",
    // layout: DefaultLayout,
    component: EnterpriseView,
  },
  {
    path: "/profile",
    layout: DefaultLayout,
    component: Profile,
  },
  {
    path: "/start",
    layout: DefaultLayout,
    component: Start,
  },

  {
    path: "/environments-list",
    layout: DefaultLayout,
    component: Projects,
  },
  {
    path: "/my_project/:id",
    layout: DefaultLayout,
    component: Project,
  },
];

function getRoutes(company) {
  let indexCounter = 1;
  const privateRoutes = PRIVATE_ROUTES.map((route) => {
    return (
      <PrivateRoute
        key={indexCounter}
        {...route}
        route={route}
        index={indexCounter++}
        redirectPath={company.externalLoginUrl}
        component={withTracker((props) => {
          return route.layout ? (
            <route.layout {...props}>
              <route.component {...props} />
            </route.layout>
          ) : (
            <route.component {...props} />
          );
        })}
      />
    );
  });
  const publicRoutes = PUBLIC_ROUTES.map((route) => {
    if (
      company.externalLoginUrl &&
      (route.path === "/login" || route.path === "/register")
    ) {
      route.component = () => {
        //eslint-disable-next-line
        useEffect(() => {
          window.open(company.externalLoginUrl, "_self");
        }, []);
        return <> </>;
      };
      route.layout = null;
    }
    return (
      <Route
        key={indexCounter++}
        path={route.path}
        exact={route.exact}
        component={withTracker((props) => {
          return route.layout ? (
            <route.layout {...props}>
              <route.component {...props} />
            </route.layout>
          ) : (
            <route.component {...props} />
          );
        })}
      />
    );
  });
  return publicRoutes.concat(privateRoutes);
}

const Routes = () => {
  const { company } = useContext(SystemContext);
  return (
    <Switch>
      {getRoutes(company)}
      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
