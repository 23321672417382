import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, CardBody, Container, Button, CardHeader } from "shards-react";
import {
  getProjectV2,
  updateLogDates,
  updateProjectSubmit,
  updateProjectStep,
} from "../actions/project";
import { showReviewProjects } from "../utils/util";
import ConfirmInformation from "../components/environments/steps/ConfirmInformation";
import FurnitureMeasures from "../components/environments/steps/FurnitureMeasures";
import Inspiration from "../components/environments/steps/Inspiration";
import LearningEnvironment from "../components/environments/steps/LearningEnvironment";
import SetNameProject from "../components/environments/steps/SetNameProject";
import StepsForm from "../components/environments/steps/StepsForm";
import Wizard from "../components/environments/steps/Wizard";
import Review from "../components/environments/steps/Review";
import Summary from "../components/Project/Summary";
import DownloadProject from "../components/environments/steps/DownloadProject";
import Loader from "react-spinners/BounceLoader";
import { getStatusInfo } from "@ladecora/ladecora-utils";
import { AuthContext } from "../components/common/Authorization/AuthContext";
import { SocketIoContext } from "../components/shared/SocketIoContext";

const Project = () => {
  const { id } = useParams();
  const [project, setProject] = useState();
  const [showSummary, setShowSummary] = useState(false);
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [step, setStep] = useState(-1);
  const { registerEventHandler, removeEventHandler } =
    useContext(SocketIoContext);

  const handleNotification = useCallback((res) => {
    if (res.room === user._id) {
      if (res.project) {
        getProjectV2(id).then((p) => {
          setProject(p);
        });
      }
      if (res.notification?.data?.asaasPaymentId) {
        window.location.reload(false);
      }
    }
  }, []);

  useEffect(() => {
    registerEventHandler("project", handleNotification);
    return () => {
      removeEventHandler("project", handleNotification);
    };
  }, []);

  const checkForcedStep = (toCheck) => {
    if (toCheck) {
      if (toCheck.length === 0) {
        return false;
      } else {
        return toCheck.some((elem) => elem.isPending === true);
      }
    }
    return false;
  };

  const updateProjectName = (name, justNextStep) => {
    if (justNextStep && Math.max(...project.completedSteps) >= 2) {
      setStep(2);
      return Promise.resolve(null);
    } else {
      return updateProjectStep(project._id, "project_name", { name }).then(
        (res) => {
          setProject({
            ...project,
            name: name,
            completedSteps: res.completedSteps,
          });
          setStep(2);
          !justNextStep && toast.success("Nome salvo com sucesso");
        }
      );
    }
  };

  const updateProjectQuestionary = (answers, justNextStep) => {
    const isForced =
      project.forcedStep && !!project.forcedStep.find((s) => s.key === "2");
    if (justNextStep) {
      setStep(3);
      return Promise.resolve(null);
    } else {
      return updateProjectStep(project._id, "questionary", {
        answers,
        forced: isForced,
      }).then((res) => {
        const fs = getUpdatedForcedStep(project);
        let viewInfo = project._viewInfo;
        if (isForced) {
          viewInfo = getStatusInfo(
            {
              ...project,
              completedSteps: res.completedSteps,
              forcedStep: fs,
            },
            [],
            []
          )._viewInfo;
        }

        setProject({
          ...project,
          answers: answers,
          completedSteps: res.completedSteps,
          forcedStep: fs,
          _viewInfo: viewInfo,
        });
        if (!isForced) {
          setStep(3);
        }

        return res;
      });
    }
  };

  const updateProjectLearningEnvironment = (learningEnvironment) => {
    const isForced =
      project.forcedStep && !!project.forcedStep.find((s) => s.key === "3");
    return updateProjectStep(project._id, "learning_environment", {
      learningEnvironment,
      forced: isForced,
    }).then((res) => {
      const fs = getUpdatedForcedStep(project);
      let viewInfo = project._viewInfo;
      if (isForced) {
        viewInfo = getStatusInfo(
          {
            ...project,
            learningEnvironment: learningEnvironment,
            completedSteps: res.completedSteps,
            forcedStep: fs,
          },
          [],
          []
        )._viewInfo;
      }

      setProject({
        ...project,
        learningEnvironment: learningEnvironment,
        completedSteps: res.completedSteps,
        forcedStep: fs,
        _viewInfo: viewInfo,
      });
      if (!isForced) {
        setStep(4);
      }

      return res;
    });
  };

  const updateProjectFurnitureMeasures = (furnitureMeasures) => {
    const isForced =
      project.forcedStep && !!project.forcedStep.find((s) => s.key === "4");
    return updateProjectStep(project._id, "furniture_measures", {
      furnitureMeasures,
      forced: isForced,
    }).then((res) => {
      const fs = getUpdatedForcedStep(project);
      let viewInfo = project._viewInfo;
      if (isForced) {
        viewInfo = getStatusInfo(
          {
            ...project,
            completedSteps: res.completedSteps,
            forcedStep: fs,
          },
          [],
          []
        )._viewInfo;
      }
      setProject({
        ...project,
        furnitureMeasures: furnitureMeasures,
        completedSteps: res.completedSteps,
        _viewInfo: viewInfo,
        forcedStep: fs,
      });
      if (!isForced) {
        setStep(5);
      }

      return res;
    });
  };

  const updateProjectInspiration = (inspirations) => {
    const isForced =
      project.forcedStep && !!project.forcedStep.find((s) => s.key === "5");
    return updateProjectStep(project._id, "inspirations", {
      inspirations,
      forced: isForced,
    }).then((res) => {
      const fs = getUpdatedForcedStep(project);
      let viewInfo = project._viewInfo;
      if (isForced) {
        viewInfo = getStatusInfo(
          {
            ...project,
            completedSteps: res.completedSteps,
            forcedStep: fs,
          },
          [],
          []
        )._viewInfo;
      }
      setProject({
        ...project,
        inspirations: inspirations,
        completedSteps: res.completedSteps,
        forcedStep: fs,
        _viewInfo: viewInfo,
      });
      if (!isForced) {
        setStep(6);
      }

      return res;
    });
  };

  const getUpdatedForcedStep = (project) => {
    if (project.forcedStep && project.forcedStep.length) {
      const forcedStep = JSON.parse(JSON.stringify(project.forcedStep));
      for (let i = 0; i < forcedStep.length; i++) {
        const fs = forcedStep[i];
        if (fs.isPending) {
          fs.isPending = false;
          break;
        }
      }
      return forcedStep;
    }
    return [];
  };

  const goBack = () => {
    history.replace("/environments-list");
  };

  const [lastStep, reviewsToShow] = useMemo(() => {
    if (project) {
      if (checkForcedStep(project.forcedStep)) {
        for (let i = 0; i < project.forcedStep.length; i++) {
          const s = project.forcedStep[i];
          if (s.isPending) {
            return [parseInt(s.key), showReviewProjects(project)];
          }
        }
      }
      if (step !== -1) return [step, showReviewProjects(project)];
      const ls =
        project.status !== "enabled" ? 0 : Math.max(...project.completedSteps);

      return [ls, showReviewProjects(project)];
    }
    return [null, null];
  }, [project, step]);

  useEffect(() => {
    getProjectV2(id)
      .then((res) => {
        setProject(res);
      })
      .catch((err) => {
        return history.replace("/environments-list");
      });
  }, [id]);

  return project ? (
    <Container
      fluid
      className="main-content-container px-4"
      style={{ resize: "both" }}
    >
      <ToastContainer />

      <h4 className="text-center mt-4">{project.name}</h4>

      {lastStep > 0 ||
      project.status === "submitted" ||
      (project.forcedStep?.length && checkForcedStep(project.forcedStep)) ? (
        <StepsForm
          activeStep={lastStep === 0 ? 7 : lastStep}
          project={project}
        />
      ) : (
        <hr className="mb-5" />
      )}

      {lastStep === 1 ? (
        <div className="d-flex">
          <Card small className="mt-5 ml-auto mr-auto ">
            <CardHeader className="p-0 pt-2 pr-2">
              <i
                className="fa fa-times"
                onClick={goBack}
                style={{ fontSize: "22px", cursor: "pointer", float: "right" }}
                aria-hidden="true"
              />
            </CardHeader>
            <CardBody>
              <SetNameProject
                project={project}
                callbackReturnView={() => {}}
                updateStepCompleted={(data, isPristine) => {
                  return updateProjectName(data.name, isPristine);
                }}
              />
            </CardBody>
          </Card>
        </div>
      ) : null}
      {lastStep === 2 ? (
        <Wizard
          onClose={() => {
            setStep(6);
          }}
          project={project}
          goBack={goBack}
          onPrevious={setStep}
          showSummary={showSummary}
          callbackReturnView={() => {}}
          updateStepCompleted={(data, isPristine) => {
            return updateProjectQuestionary(data.answers, isPristine);
          }}
        />
      ) : null}
      {lastStep === 3 ? (
        <Card small className="mt-3 ml-auto mr-auto ">
          <CardHeader className="p-0 pt-2 pr-2">
            <i
              className="fa fa-times"
              onClick={goBack}
              style={{ fontSize: "22px", cursor: "pointer", float: "right" }}
              aria-hidden="true"
            />
          </CardHeader>
          <CardBody>
            <LearningEnvironment
              project={project}
              showSummary={showSummary}
              onClose={() => {
                setStep(6);
              }}
              callbackReturnView={() => {}}
              goBack={setStep}
              updateStepCompleted={(data, isPristine) => {
                return updateProjectLearningEnvironment(
                  data.learningEnvironment
                );
              }}
            />
          </CardBody>
        </Card>
      ) : null}
      {lastStep === 4 ? (
        <Card small className="mt-3 ml-auto mr-auto ">
          <CardHeader className="p-0 pt-2 pr-2">
            <i
              className="fa fa-times"
              onClick={goBack}
              style={{ fontSize: "22px", cursor: "pointer", float: "right" }}
              aria-hidden="true"
            />
          </CardHeader>
          <CardBody>
            <FurnitureMeasures
              showSummary={showSummary}
              onClose={() => {
                setStep(6);
              }}
              project={project}
              goBack={setStep}
              callbackReturnView={() => {}}
              updateStepCompleted={(data) => {
                return updateProjectFurnitureMeasures(data.furnitureMeasures);
              }}
            />
          </CardBody>
        </Card>
      ) : null}
      {lastStep === 5 ? (
        <Card small className="mt-3 ml-auto mr-auto ">
          <CardHeader className="p-0 pt-2 pr-2">
            <i
              className="fa fa-times"
              onClick={goBack}
              style={{ fontSize: "22px", cursor: "pointer", float: "right" }}
              aria-hidden="true"
            />
          </CardHeader>
          <CardBody>
            <Inspiration
              showSummary={showSummary}
              onClose={() => {
                setStep(6);
              }}
              project={project}
              goBack={setStep}
              callbackReturnView={() => {}}
              updateStepCompleted={(data) => {
                return updateProjectInspiration(data.inspirations);
              }}
            />
          </CardBody>
        </Card>
      ) : null}
      {lastStep === 6 && !showSummary ? (
        <Card small className="mt-3 ml-auto mr-auto ">
          <CardHeader className="p-0 pt-2 pr-2">
            <i
              className="fa fa-times"
              onClick={goBack}
              style={{ fontSize: "22px", cursor: "pointer", float: "right" }}
              aria-hidden="true"
            />
          </CardHeader>
          <CardBody>
            <ConfirmInformation
              project={project}
              goBack={() => {}}
              showSummary={() => setShowSummary(!showSummary)}
              sendProject={() => {
                return Promise.all([
                  updateLogDates(project._id, "submitted"),
                  updateProjectSubmit(project._id),
                ]).then((res) => {
                  setProject({
                    ...project,
                    status: "submitted",
                    completedSteps: res[1].completedSteps,
                    _viewInfo: res[1]._viewInfo,
                  });
                  setStep(-1);
                  toast.success("Informações enviadas com sucesso.");
                  return res;
                });
              }}
            />
          </CardBody>
        </Card>
      ) : null}

      {lastStep === 0 ? (
        project.status === "approved" ||
        project.status === "submitted" ||
        (reviewsToShow.preview === false &&
          reviewsToShow.firstReview === false &&
          reviewsToShow.secondReview === false) ? (
          <Card small className="mt-3 ml-auto mr-auto ">
            <CardBody>
              <div className="text-center">
                <h4>{project?._viewInfo.header}</h4>
                <div className="py-3">{project?._viewInfo.text}</div>
                <Button
                  theme="accent"
                  size="lg"
                  onClick={() => {
                    history.replace("/environments-list");
                  }}
                >
                  Meus ambientes
                </Button>
              </div>
            </CardBody>
          </Card>
        ) : project.status === "final_project" ? (
          <DownloadProject
            project={project}
            goBack={goBack}
            callbackReturnView={() => {}}
            updateStepCompleted={() => {}}
          />
        ) : (
          <Card small className="mt-3 ml-auto mr-auto ">
            <CardHeader>
              <i
                className="fa fa-times"
                onClick={goBack}
                style={{ fontSize: "22px", cursor: "pointer", float: "right" }}
                aria-hidden="true"
              />
            </CardHeader>
            <CardBody>
              <Review
                project={project}
                goBack={() => {}}
                callbackReturnView={(p) => {
                  setProject({
                    ...project,
                    status: p.status,
                    logDates: p.logDates,
                    finalProject: {
                      ...project.finalProject,
                      approvedProject: p.finalProject.approvedProject,
                    },
                  });
                }}
                updateStepCompleted={() => {}}
              />
            </CardBody>
          </Card>
        )
      ) : null}

      {showSummary && lastStep === 6 ? (
        <Summary
          project={project}
          onConfirm={() => {
            setShowSummary(false);
          }}
          onStepClick={(step) => {
            setStep(step);
            // setShowSummary(false);
          }}
        />
      ) : null}
    </Container>
  ) : (
    <div
      style={{ height: "80vh", width: "100%" }}
      className="d-flex align-items-center justify-content-center"
    >
      <Loader width="60px" height="60px" type="Rings" color="black" />
    </div>
  );
};
export default Project;
